import { Box, Drawer, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/styles';
import PlanCard from 'components/PlanCard';
import {
  // getPlanByDate,
  fetchDistanceMatrices,
  fetchDistanceMatrix,
  fetchRoutes,
  fetchRoutesv1,
  getPlan,
  useGetPlanDetails,
} from 'delivery/services/planService';
import { navigate } from 'gatsby';
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import {
  useAPI,
  useFetch,
  useFetchOnAction,
  useFetchv1,
  useInterval,
  usePrevious,
} from 'utils/customHooks';
import { formatTimeSec, isEmpty, isVal } from 'utils/utils';
import PlanPolishingMap from './PlanPolishingMap';
import PlanningActionPanel from './PlanningActionPanel';
import PlanningTable from './PlanningTable';
import reducer, { actionTypes } from './planningReducer';
// import { useGState, useGDispatch, ga } from 'state/store';
// import { dismiss } from 'components/SnackbarActions';
// import { format, addDays, subDays } from 'date-fns/esm';
import classNames from 'classnames';
import fetchPromise from 'utils/fetch';
import optimize from 'utils/pathOptimization';
import PlanAdditionalActions from './PlanAdditionalActions';
import PlanningSidebar from './PlanningSidebar';

const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  divider: {
    height: 2,
    backgroundColor: grey[500],
  },
  table: {
    marginTop: theme.spacing(1),
  },
  drawer: {
    // width: props => props.ACTIVITYBAR_WIDTH,
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  body: {
    paddingTop: theme.spacing(1),
  },
  drawerOpen: {
    // width: props => props.SIDEBAR_WIDTH,
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
  },
  content: {
    flexGrow: 1,
  },
}));

export default function PlanningDashboard({ id }) {
  const classes = useStyles();
  const [planDetails, , , refetchPlanDetails] = useFetch(useGetPlanDetails(id));
  const [fetchRoutesAction, routes] = useFetchOnAction(fetchRoutesv1(id), [], false);
  const [fetch] = useAPI();
  const [fetchDM] = useAPI();
  const [state, dispatch] = useReducer(reducer, {});
  const prevState = usePrevious(state);
  // const { date: selectedDate } = useGState(state => state.date);
  // const gDispatch = useGDispatch();
  // const [notif, closeSnackbar] = useSnackbar();
  // const prevDateButton = useKeyPressed('[');
  // const nextDateButton = useKeyPressed(']');
  const [drawerState, setDrawerState] = useState(false);
  // useUnobtrusiveLogin();

  useEffect(() => {
    dispatch({ type: actionTypes.CLEAN_SLATE });
  }, [id]);

  // useDidUpdateEffect(() => {
  //   gDispatch({ type: ga.DATE, date: subDays(state.date, 1) });
  // }, [prevDateButton]);

  // useDidUpdateEffect(() => {
  //   gDispatch({ type: ga.DATE, date: addDays(state.date, 1) });
  // }, [nextDateButton]);

  // // To navigate between diff date plans
  // useDidUpdateEffect(() => {
  //   fetch(
  //     getPlanByDate(id, selectedDate),
  //     resp => resp.id && navigate(`/plan/${resp.id}`),
  //     () => {
  //       notif(`No plan for ${state.branch.name} on ${format(selectedDate, 'dd MMM yyyy')}`, {
  //         variant: 'error',
  //         persist: true,
  //         action: dismiss(key => closeSnackbar(key)),
  //       });
  //       gDispatch({ type: ga.DATE, date: state.date });
  //     }
  //   );
  // }, [selectedDate]);

  //Initial setup once the plan details has been fetched
  useEffect(() => {
    if (!planDetails) return;
    //TODO: fix additional otherdate call and date navigation to work
    // if (!isSameDay(selectedDate, planDetails.date))
    // gDispatch({ type: ga.DATE, date: planDetails.date });
    dispatch({ type: actionTypes.INITIALIZE_PLAN, planDetails });

    if (planDetails.branch && planDetails.inputs && planDetails.inputs.length) {
      const latlngs = [
        { latitude: planDetails.branch.latitude, longitude: planDetails.branch.longitude },
        ...planDetails.inputs.map(({ latitude, longitude }) =>
          latitude && longitude
            ? { latitude, longitude }
            : { latitude: planDetails.branch.latitude, longitude: planDetails.branch.longitude }
        ),
      ];
      fetchDM(fetchDistanceMatrix(latlngs, planDetails.branch.id, id), (distanceMatrix) =>
        dispatch({ type: actionTypes.DISTANCE_MATRIX, distanceMatrix })
      );
    }
  }, [planDetails, fetchDM, id]);

  //Fecthing new routes whenever the picklist retailers changes
  useEffect(() => {
    if (!prevState || !state.picklists) return;
    const { past, future, picklists } = state;

    //bailing redo
    if (
      past.length &&
      past[past.length - 1].picklists === prevState.picklists &&
      state.future.length &&
      prevState.future.length - state.future.length === 1
    )
      return;

    //bailing undo
    if (
      future.length &&
      future[0].picklists === prevState.picklists &&
      prevState.past.length - state.past.length === 1
    )
      return;

    const modifiedPicklists = Object.values(picklists).filter(
      (pl) =>
        !prevState.picklists ||
        !prevState.picklists[pl.index] ||
        pl.retailerIds !== prevState.picklists[pl.index].retailerIds
    );

    if (!modifiedPicklists.length) return;

    dispatch({ type: actionTypes.UPDATE_ETAS, modifiedPicklists });

    const meta = [];
    const routesPayload = modifiedPicklists.map((pl) => {
      meta.push(pl.index);
      return {
        retailerIds: pl.retailerIds,
        vehicleTypeId: pl.vehicleTypeId,
      };
    });
    fetchRoutesAction({ meta, data: routesPayload });
  }, [state.picklists]); // eslint-disable-line react-hooks/exhaustive-deps

  //Post processing after figuring out original plan
  useEffect(() => {
    if (!state.originalRetailers || isEmpty(state.originalRetailers)) return;
    let originalPicklists = Object.values(JSON.parse(JSON.stringify(state.originalPicklists)));
    const pathOptimize = async () => {
      try {
        const distanceMatrix = await Promise.all(
          Object.values(state.originalPicklists).map((pl) =>
            fetchPromise(fetchDistanceMatrices(id, pl.retailerIds))
          )
        );

        const optimizedPlanlists = optimize(
          state.originalRetailers,
          state.originalPicklists,
          state.branch,
          distanceMatrix,
          false
        );
        optimizedPlanlists.forEach(({ index, path: retailerIds }) => {
          originalPicklists[index].retailerIds = retailerIds;
          originalPicklists[index].pathOptimized = true;
        });

        const routesPayload = Object.values(originalPicklists).map((pl) => ({
          retailerIds: pl.retailerIds,
          vehicleTypeId: pl.vehicleTypeId,
        }));
        fetch(fetchRoutes(id, routesPayload), (routes) => {
          routes.forEach((route, index) => {
            originalPicklists[index].route = route.path;
            originalPicklists[index].travelTime = route.travelTime;
            originalPicklists[index].tripDistance = route.tripDistance;
            originalPicklists[index].routesUpdates = true;
          });
          dispatch({ type: actionTypes.UPDATE_ORIGINAL_PICKLIST, originalPicklists });
        });
      } catch (err) {
        console.log(err); // eslint-disable-line
      }
    };

    pathOptimize();
    //eslint-disable-next-line
  }, [state.originalRetailers, state.branch, fetch, id]);

  //Updating new routes in the state
  useEffect(() => {
    if (!routes || !routes.data) return;
    dispatch({
      type: actionTypes.UPDATE_ROUTES_AND_DISTANCE,
      routes: routes.meta.map((picklistIndex, index) => ({
        picklistIndex,
        route: routes.data[index].path,
        travelTime: routes.data[index].travelTime,
        tripDistance: routes.data[index].tripDistance,
      })),
    });
  }, [routes]);

  const getVehicleDetails = useCallback(
    (vehicleId) => planDetails.vehicleTypes.find((vehicle) => vehicle.id === vehicleId),
    [planDetails]
  );

  const stats = useCalculateStats(state, [
    state.retailers,
    state.picklists,
    state.originalPlanView,
    state.originalRetailers,
    state.originalPicklists,
    state.salesmanCount,
    state.metrics,
  ]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container style={{ flexGrow: 1 }}>
          <Grid item style={{ flexGrow: 1 }}>
            <PlanCardContainer id={id} fetchDetails={refetchPlanDetails} stats={stats} />
          </Grid>
          <Grid item>
            <PlanAdditionalActions drawerState={drawerState} setDrawerState={setDrawerState} />
          </Grid>
        </Grid>
        {planDetails && state.retailers && state.picklists && (
          <Grid container className={classes.body}>
            <Grid item xs={12} md={7}>
              <Box px={1}>
                <PlanPolishingMap
                  className={classes.map}
                  retailers={state.originalPlanView ? state.originalRetailers : state.retailers}
                  picklists={state.originalPlanView ? state.originalPicklists : state.picklists}
                  state={state}
                  dispatch={dispatch}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box px={1}>
                <div className={classes.actionPanel}>
                  <PlanningActionPanel
                    dispatch={dispatch}
                    retailers={state.originalPlanView ? state.originalRetailers : state.retailers}
                    picklists={Object.values(
                      state.originalPlanView ? state.originalPicklists : state.picklists
                    )}
                    state={state}
                    id={id}
                    refetchPlanDetails={refetchPlanDetails}
                    stats={stats}
                  />
                </div>
                <Divider className={classes.divider} />
                <div className={classes.table}>
                  <PlanningTable
                    retailers={state.originalPlanView ? state.originalRetailers : state.retailers}
                    picklists={Object.values(
                      state.originalPlanView ? state.originalPicklists : state.picklists
                    ).sort((p1, p2) => p1.index - p2.index)}
                    selectedPicklists={state.selectedPicklists}
                    dispatch={dispatch}
                    getVehicleDetails={getVehicleDetails}
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        )}
      </div>
      <Drawer
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: drawerState,
          [classes.drawerClose]: !drawerState,
        })}
        variant="persistent"
        anchor="right"
        open={drawerState}
        // classes={{
        //   paper: classes.drawerPaper,
        // }}
      >
        <PlanningSidebar id={id} />
      </Drawer>
    </div>
  );
}

function PlanCardContainer({ id, stats, fetchDetails }) {
  const [plan, , error, refetchPlan] = useFetchv1(getPlan(id), [id], false);
  const holdStatusCallRef = useRef(false);

  useInterval(() => {
    if (!holdStatusCallRef.current) refetchPlan();
  }, 1000 * 5);

  const holdStatusCall = useCallback((val) => {
    holdStatusCallRef.current = val;
  }, []);

  return (
    <>
      {plan && (
        <PlanCard
          plan={plan}
          stats={stats}
          fetchDetails={fetchDetails}
          holdStatusCall={holdStatusCall}
          planPage
          refetch={(plan) =>
            plan ? navigate(`/dl/plan/${plan.id}`, { replace: true }) : refetchPlan()
          }
        />
      )}
      {error && <Typography>Plan Not Found</Typography>}
    </>
  );
}

const useCalculateStats = (state, deps) => {
  return useMemo(() => {
    if (!state.picklists)
      return {
        vehicles: '-',
        assigned: '-',
        unAssigned: '-',
        noGPS: '-',
        salesmanCount: '-',
        maxOverlap: '-',
        totalOverlap: '-',
      };
    const retailersArr = Object.values(
      state.originalPlanView ? state.originalRetailers : state.retailers
    );
    const picklistsArr = Object.values(
      state.originalPlanView ? state.originalPicklists : state.picklists
    );
    return {
      vehicles: picklistsArr.length,
      assigned: retailersArr.filter((r) => isVal(r.index)).length,
      unAssigned: retailersArr.filter(
        ({ latitude, longitude, index }) => latitude && longitude && !isVal(index)
      ).length,
      noGPS: retailersArr.filter(({ latitude, longitude }) => !latitude || !longitude).length,
      sales: retailersArr.reduce((acc, { sales }) => acc + sales, 0),
      salesmanCount: state.salesmanCount,
      maxOverlap: state.metrics.maxOverlap,
      totalOverlap: state.metrics.totalOverlap,
      totalCost: formatTimeSec(
        Object.values(state.picklists)?.reduce((acc, pl) => acc + calculateCost(pl, state), 0)
      ),
    };
    //eslint-disable-next-line
  }, deps);
};

function calculateCost(picklist, { retailers, distanceMatrix, vehicleTypes }) {
  const { retailerIds, vehicleTypeId, travelTime, serviceTime = 0 } = picklist;
  if (!retailerIds.length) return 0;
  const retailerId = retailerIds.at(-1);
  const dmIndex = retailers[retailerId]?.dmIndex;
  const distance = distanceMatrix?.[dmIndex][0];
  const vehicle = vehicleTypes?.find((vt) => vt.id === vehicleTypeId);
  const time = (distance / vehicle.avgSpeed) * (5 / 18);
  return travelTime + serviceTime - time + vehicle.fixedCost;
}
